(function() {
  angular.module("EntrakV5").controller("scheduleController", scheduleController);

  function scheduleController($scope, $rootScope, $q, Service, KEY, APIKEY, Api) {
    console.log("scheduleController");

    var caller = Api.createApiCaller();
    $rootScope.title = Service.translate("schedule.title");

    var publicHoliday = "PUBLIC_HOLIDAY";

    $scope.days = APIKEY.days.map(function(v, i) {
      return {
        name: window.kendo.cultures.current.calendar.days.namesAbbr[i],
        key: v,
      }
    });
    $scope.todayDayKey = APIKEY.days[new Date().getDay()];
    $scope.btnStatus = {};

    $scope.nodes = null; //set null to disabled edit btn before api called
    $scope.schedules = null;
    $scope.cities = null;
    $scope.specialDays = null;
    $scope.alwaysOnMap = {};
    $scope.depts = {};
    var fullDeptList = {
      noDept: {
        name: Service.translate("label.noDept"),
        order: 99999
      }
    }

    $scope._sortName = Service.getSorter();

    /* first load */
    $rootScope.resetColorIndex();
    $rootScope.loadingPage = 4;
    $rootScope.getTenantId().then(function(tenantId){
      caller.call([Api.getSchedulePageNodes(tenantId), Api.getDepartments(tenantId)]).then(function(res) {
        //prepare fullDeptList
        res[1].sort($scope._sortName);
        res[1].forEach(function(d, i){
          fullDeptList[d.id] = {
            name: d.name,
            order: i
          }
        });
        //process data
        var data = res[0];
        if (data.zones && data.zones.length){
          data.zones = $rootScope.getFilteredZoneList(data.zones);
          var workstations = processWorkstations(data.zones.reduce(function(arr, floor){
            if (floor.alwaysOn)
              floor.workstations.forEach(n => $scope.alwaysOnMap[n.id] = true);
            return arr.concat(floor.workstations);
          }, []));
          var rooms = processRooms(data.zones.reduce(function(arr, floor){
              if (floor.alwaysOn)
                floor.rooms.forEach(n => $scope.alwaysOnMap[n.id] = true);
            return arr.concat(floor.rooms);
          }, []));
          data.zones.sort($scope._sortName);
          data.zones.unshift({
            id: 'all',
            name: Service.translate("schedule.allFloor"),
          });
          $scope.floorDropdown.setDataSource(new kendo.data.DataSource({
            data: data.zones
          }));
          $scope.nodes = workstations.concat(rooms);
        } else {
          $scope.nodes = [];
        }
        $rootScope.loadingPage--;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.generalErrorHandler();
      });
    });
    caller.call(Api.getSchedules()).then(function(res) {
      processSchedules(res);
      $rootScope.loadingPage--;
    }, function(err) {
      if (err === KEY.ignore)
        return;
      $rootScope.generalErrorHandler();
    });
    caller.call(Api.getTenantCities()).then(function(res) {
      $scope.cities = res;
      $scope.cityDropdown.setDataSource(new kendo.data.DataSource({ data: res }));
      $rootScope.loadingPage--;
    }, function(err) {
      if (err === KEY.ignore)
        return;
      $rootScope.generalErrorHandler();
    });
    caller.call(Api.getSpecialDays()).then(function(res) {
      res.push({
        id: publicHoliday,
        name: Service.translate("label.publicHolidays"),
      });
      res.sort($scope._sortName);
      $scope.specialDays = res;
      $rootScope.loadingPage--;
    }, function(err) {
      if (err === KEY.ignore)
        return;
      $rootScope.generalErrorHandler();
    });

    function processWorkstation(ws) {
      Service.updateWsName(ws);
      ws.deptId = ws.owners[0] && ws.owners[0].departmentId ? ws.owners[0].departmentId : "noDept";
	  
      if (!$scope.depts[ws.deptId]) {
        $scope.depts[ws.deptId] = {
          name: fullDeptList[ws.deptId].name,
          color: $rootScope.getNextColor(),
          expanded: true,
        }
      }
    }
    function processWorkstations(list) {
      //assign ws.deptId and init depts obj
      list.forEach(processWorkstation);
      //sort ws
      return list.sort(function(a, b) {
        var deptA = fullDeptList[a.deptId].order;
        var deptB = fullDeptList[b.deptId].order;
        if (deptA === deptB) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        } else {
          return deptA - deptB;
        }
      });
    }

    function processRoom(rm) {
      rm.deptId = 'room';
    }
    function processRooms(list) {
      if (list && list.length){
        list.sort($scope._sortName);
        list.forEach(processRoom);
        if (!$scope.depts['room']){
          $scope.depts['room'] = {
            name: Service.translate("nodeType." + APIKEY.nodeTypeInv[APIKEY.nodeType.room]),
            color: "#9779ff",
            expanded: true,
          }
        }
      }
      return list;
    }
    function processSchedules(list) {
      list.forEach(function(sch) {
        sch.hasDayMap = {};
        sch.todayTimeslots = [];
        Service.sortSchTimeslot(sch);
        sch.timeslots.forEach(function(ts) {
          sch.hasDayMap[ts.weekday] = true;
          if ($scope.todayDayKey === ts.weekday) {
            sch.todayTimeslots.push({
              autoCheckin: ts.start.action === APIKEY.scheduleAction.auto,
              lbl: Service.timeslotTimeToStr(ts.start) + "-" + Service.timeslotTimeToStr(ts.end),
            });
          }
        });
      });

      if ($scope.schedules && $scope.schedules.length) {
        Service.replaceArrItem($scope.schedules, list, true);
      } else {
        $scope.schedules = list;
      }
      $scope.schedules.sort($scope._sortName);
    }
    /* first load */

    /* left menu */
    $scope.floorId = "all";
    $scope.draggingDeptId = null;
    $scope.isEditMode = false;
    $scope.searchText = "";

    $scope.floorDropdownOpt = {
      dataSource: [{
        id: 'all',
        name: Service.translate("schedule.allFloor"),
      }],
      dataTextField: "name",
      dataValueField: "id"
    }

    $scope.editNodeSchedule = function() {
      $scope.searchText = "";
      $scope.isEditMode = true;
    }
    $scope.cancelEditNodeSchedule = function() {
      $scope.searchText = "";
      $scope.floorId = "all";
      $scope.isEditMode = false;
    }

    //menu filter
    $scope.scheduleFilter = function(ttId) {
      return function(node) {
        return node.timetableId == ttId;
      }
    }
    $scope.nameFilter = function() {
      var txt = $scope.searchText.trim();
      if (txt) {
          txt = txt.toLowerCase();
          return function(node) {
            return node.name.toLowerCase().indexOf(txt) > -1;
          }
      }
    }
    $scope.floorFilter = function(){
      if ($scope.floorId != 'all'){
        return function(node) {
          return node.zoneId === $scope.floorId;
        }
      }
    }

    $scope.getNodeCount = function(ttId) {
      var count = 0;
      if ($scope.nodes) {
        for (var i = 0; i < $scope.nodes.length; i++) {
          if ($scope.nodes[i].timetableId == ttId)
            count++;
        }
      }
      return count;
    }

    $scope.expandDept = function(deptId) {
      $scope.depts[deptId].expanded = !$scope.depts[deptId].expanded;
    }

    $scope.onDragDeptStart = function(deptId) {
      $scope.draggingDeptId = deptId;
      $scope.depts[deptId].isDeptDragging = true;
    }
    $scope.$on("draggable:move", function(a, b) {
      if (b.event.clientY < 120){
        autoScroll(-25);
      } else if (b.event.clientY > window.innerHeight - 60){
        autoScroll(25);
      } else {
        autoScroll();
      }
    });
    $scope.$on("draggable:end", function(a, b) {
      if ($scope.depts[$scope.draggingDeptId]) {
        $scope.depts[$scope.draggingDeptId].isDeptDragging = false;
        $scope.draggingDeptId = null;
      }
      autoScroll();
    });

    var scrollTimer = null;
    function autoScroll(scrollVal){
      if (!scrollVal){
        if (scrollTimer != null){
          clearInterval(scrollTimer);
          scrollTimer = null;
        }
      } else if (scrollTimer == null){
        var schCardsElm = document.getElementById("autoScroll");
        function scrollCards(){
          schCardsElm.scrollBy(0, scrollVal);
        }
        scrollTimer = setInterval(scrollCards, 50);
      }
    }

    function tryMoveNode(node, ttId) {
      node.origTimetableId = node.timetableId;
      node.timetableId = ttId;
      node.moving = true;
    }
    function moveNodeFail(node) {
      node.timetableId = node.origTimetableId;
      node.origTimetableId = null;
      node.moving = false;
    }

    function updateNodeScheduleSuccess(res) {
      if (!Array.isArray(res))
        res = [res];
      res.forEach(function(n){
        if (Service.isWorkstation(n)){
          processWorkstation(n);
        } else {
          processRoom(n);
        }
      });
      Service.replaceArrItem($scope.nodes, res);
    }

    //filter out the invisible node for the dragging department
    function getFilteredNodes(nodes, deptId){//assume must be drag from left menu, so ttId = null
      var schFilter = $scope.scheduleFilter(null);
      var nFilter = $scope.nameFilter();
      var fFilter = $scope.floorFilter();
      var res = nodes.filter(function(n){
        return schFilter(n) && n.deptId === deptId && !n.moving;
      });

      if (fFilter)
        res = res.filter(fFilter);
      if (nFilter)
        res = res.filter(nFilter);
      return res;
    }

    $scope.onDropSuccess = function(data, ttId) {
      if (data) {
        if (typeof data === "string") {
          //drop dept/room case, assume only drag from list to schedule
          if (ttId != null) {
            var nodes = getFilteredNodes($scope.nodes, data);
            nodes.forEach(function(n){
              tryMoveNode(n, ttId);
            });
            if (nodes.length) {
              caller.call(Api.addNodesToSchedule(ttId, nodes.map(function(n) {
                return {
                  id: n.id,
                  nodeType: (n.deptId === 'room' ? APIKEY.nodeType.room : APIKEY.nodeType.workstation),
                }
              }))).then(updateNodeScheduleSuccess, function(err) {
                if (err === KEY.ignore)
                  return;
                nodes.forEach(moveNodeFail);
                if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message.indexOf("always on") > -1){
                  $rootScope.showError(Service.translate("error.alwaysOnNoSchedule"));
                } else {
                  $rootScope.generalErrorHandler(true);
                }
              });
            }
          }
        } else {
          //drop node case
          if (ttId !== data.timetableId) {
            tryMoveNode(data, ttId);
            var updateNodeScheduleFail = function(err){
              if (err === KEY.ignore)
                return;
              moveNodeFail(data);
              if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message.indexOf("always on") > -1){
                $rootScope.showError(Service.translate("error.alwaysOnNoSchedule"));
              } else {
                $rootScope.generalErrorHandler(true);
              }
            }
            if (ttId == null) {
              if (data.origTimetableId) {
                caller.call(Api.removeNodeFromSchedule(data.id)).then(updateNodeScheduleSuccess, updateNodeScheduleFail);
              }
            } else {
              if (data.origTimetableId) {
                caller.call(Api.moveScheduleNode(ttId, data.id)).then(updateNodeScheduleSuccess, updateNodeScheduleFail);
              } else {
                caller.call(Api.addNodesToSchedule(ttId, [{
                  id: data.id,
                  nodeType: Service.isWorkstation(data) ? APIKEY.nodeType.workstation : APIKEY.nodeType.room,
                }])).then(updateNodeScheduleSuccess, updateNodeScheduleFail);
              }
            }
          }
        }
      }
    }
    /* left menu */

    /* edit schedule window */
    $scope.editData = {};
    $scope.editSpDayData = {
      creatingSpDay: false
    }

    $scope.editScheduleWinOpt = {
      width: "900px",
      modal: true,
      draggable: false,
      visible: false,
      resizable: false,
      actions: ["Close"],
      open: function() {
        $scope.$apply(function() {
          $scope.btnStatus.savingSch = false;
        });
      },
    }

    $scope.cityDropdownOpt = {
      autoWidth: true,
      dataSource: [],
      dataValueField: "id",
      template: "#:data.country#/#:data.city#",
      valueTemplate: "#:data.country#/#:data.city#",
    }

    $scope.showEditScheduleWin = function(sch) {
      //schedule
      if (sch) {
        $scope.editScheduleWin.title(Service.translate("schedule.titleEditSch"));
        $scope.editData.schedule = jQuery.extend(true, {}, sch);
        $scope.editData.cityId = sch.cityId;
      } else {
        $scope.editScheduleWin.title(Service.translate("schedule.titleCreateSch"));
        $scope.editData.schedule = { timeslots: [] };
        $scope.editData.cityId = $scope.cities.length ? $scope.cities[0].id : null;
      }
      $scope.editData.initScheduleData();
      //special day
      $scope.editSpDayData.newSpDay = '';
      $scope.editSpDayData.selectAll = false;
      $scope.editSpDayData.schedule = { timeslots: [] };
      $scope.editSpDayData.originExceptionIds = [];
      var custDayList = [];
      if (sch && sch.exceptions) {
        sch.exceptions.forEach(function(exDay) {
          var specialDayId = exDay.calendarId ? exDay.calendarId : publicHoliday;
          $scope.editSpDayData.originExceptionIds.push({
            id: exDay.id,
            specialDayId: specialDayId,
          });
          custDayList[exDay.priority] = {
            id: specialDayId,
            name: Service.getArrItem($scope.specialDays, specialDayId).name,
          }
          exDay.slots.forEach(function(ts) {
            $scope.editSpDayData.schedule.timeslots.push({
              id: ts.id,
              weekday: specialDayId,
              start: ts.start,
              end: ts.end,
            });
            if (ts.end.hour == 24 && ts.end.minute == 0) //only need endDayAction if reach 24:00
              custDayList[exDay.priority].dayEndAction = ts.end.action;
          });
        });
        custDayList = custDayList.filter(function(item) {
          //in case priority is not continuous, remove the undefined item in array
          return item;
        });
      }
      $scope.editSpDayData.initScheduleData(false, custDayList);
      $scope.setSelectAll(false);
      $scope.editSpDayData.showSpDayList = custDayList.length == 0;

      setTimeout(function() {
        $scope.editScheduleWin.center().open();
      });
    }

    $scope.toggleSelectAll = function(){
      $scope.setSelectAll(!$scope.editSpDayData.selectAll);
    }
    $scope.setSelectAll = function(selected){
      $scope.specialDays.forEach(function(item){
        item.selected = selected;
      });
    }

    $scope.toggleSpDayView = function(){
      $scope.editSpDayData.showSpDayList = !$scope.editSpDayData.showSpDayList;
      if ($scope.editSpDayData.showSpDayList){
        $scope.updateSpDayListView();
      } else {
        $scope.updateSpDayTimeslotView();
      }
    }
    $scope.updateSpDayListView = function(){
      $scope.specialDays.forEach(function(item){
        item.selected = Service.getArrItem($scope.editSpDayData.custDayList, item.id) != null;
      });
    }
    $scope.updateSpDayTimeslotView = function(){
      $scope.specialDays.forEach(function(item){
        if (item.selected){
          if (!Service.getArrItem($scope.editSpDayData.custDayList, item.id))
            $scope.editSpDayData.addCustomDay(item.id, item.name);
        } else {
          if (Service.getArrItem($scope.editSpDayData.custDayList, item.id))
            $scope.editSpDayData.removeCustomDay(item.id);
        }
      });
    }

    $scope.createSpecialDay = function(){
      $scope.editSpDayData.creatingSpDay = true;
      var name = $scope.editSpDayData.newSpDay.trim();
      caller.call(Api.createSpecialDay(name, [])).then(function(res) {
        res.selected = true;
        $scope.specialDays.push(res);
        $scope.specialDays.sort($scope._sortName);
        $scope.editSpDayData.newSpDay = '';
        $("#newSpDayInput").focus();
        $scope.editSpDayData.creatingSpDay = false;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $scope.editSpDayData.creatingSpDay = false;
        $rootScope.showError(Service.translate("error.generalSaveFail"));
      });
    }

    $scope.saveSchedule = function() {
      $scope.btnStatus.savingSch = true;
      //exceptionalTimeslots
      if ($scope.editSpDayData.showSpDayList)
        $scope.updateSpDayTimeslotView();
      var spTimeslots = $scope.editSpDayData.schedule.timeslots.concat($scope.editSpDayData.schedule.deletedTimeslots);
      var exceptionIds = $scope.editSpDayData.originExceptionIds.slice();
      var exceptions = $scope.editSpDayData.custDayList.map(function(custDay, i) {
        var tmp = { priority: i, slots: [] };
        var exception = Service.deleteArrItem(exceptionIds, custDay.id, "specialDayId");
        if (exception)
          tmp.id = exception.id;
        if (custDay.id !== publicHoliday)
          tmp.calendarId = custDay.id;
        spTimeslots.forEach(function(ts) {
          if (ts.weekday === custDay.id)
            tmp.slots.push(ts);
        });
        return tmp;
      });
      exceptions = exceptions.concat(exceptionIds.map(function(ex) {
        return {
          id: ex.id,
          delete: true,
        }
      }));
      //timeslots
      var sch = $scope.editData.schedule;
      if (sch.id) {
        sch.deletedTimeslots.forEach(function(ts) {
          delete ts.weekday;
        });
        var promise = Api.updateSchedule(sch.id, sch.name, $scope.editData.cityId, sch.deletedTimeslots.concat(sch.timeslots), exceptions);
      } else {
        var promise = Api.createSchedule(sch.name, $scope.editData.cityId, sch.timeslots, exceptions);
      }
      caller.call(promise).then(function(res) {
        processSchedules([res]);
        $scope.editScheduleWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.generalErrorHandler(true);
      });
    }

    $scope.deleteSchedule = function() {
      $scope.btnStatus.savingSch = true;
      var id = $scope.editData.schedule.id;
      caller.call(Api.deleteSchedule(id)).then(function(res) {
        Service.deleteArrItem($scope.schedules, id);
        $scope.nodes.forEach(function(node) {
          if (node.timetableId === id)
            node.timetableId = null;
        });
        $scope.editScheduleWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.generalErrorHandler(true);
      });
    }

    $scope.isSpecialDayValid = function() {
      var dayList = $scope.editSpDayData.custDayList;
      for (var i = 0; i < dayList.length; i++) {
        if (dayList[i].reachDayEnd && !dayList[i].dayEndAction)
          return false;
      }
      return true;
    }
    /* edit schedule window */

    $scope.$on("$destroy", function() {
      console.log("scheduleController destroy");
      clearInterval(scrollTimer);
      scrollTimer = null;
      caller.cancel();
    });
  }
})();
